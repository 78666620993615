import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AudioApi from '@phoenix7dev/audio-api';
import { Button, RangeSlider, Toggle } from '@phoenix7dev/shared-components';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs } from '../../config';
import { PopupOpeningTypes } from '../../consts';
import {
  setAutoSpinsAmount,
  setAutoSpinsLeft,
  setAutoSpinsStartBalance,
  setCoinAmount,
  setCoinValue,
  setIsAutoSpins,
  setIsOpenAutoplayPopup,
  setIsPopupOpeningInProgress,
  setIsStopOnAnyWin,
  setIsStopOnBalanceDecrease,
  setIsStopOnBalanceIncrease,
  setIsStopOnFeatureWin,
  setIsStopOnWinExceeds,
  setLastRegularWinAmount,
  setSlotConfig,
  setStopOnBalanceDecrease,
  setStopOnBalanceIncrease,
  setStopOnBalanceIncreaseSliderValue,
  setStopOnWinExceeds,
  setStopOnWinExceedsSliderValue,
} from '../../gql/cache';
import { ISlotConfig } from '../../gql/d';
import { getAutoSpinsSettingsGql, getBetAmountGql, getUserGql } from '../../gql/query';
import { normalizeCoins, showCurrency } from '../../utils';

import styles from './autoPlaySettings.module.scss';
import { IAutoPlaySettingsProps } from './d';

const AutoPlaySettings: React.FC<IAutoPlaySettingsProps> = (props) => {
  const { t } = useTranslation();
  const { className = '' } = props;
  const {
    clientSettings: { autoplay },
  } = useReactiveVar(setSlotConfig) as RecursiveNonNullable<ISlotConfig>;
  const { data } = useQuery(getAutoSpinsSettingsGql);

  const { data: userData } = useQuery(getUserGql);
  const { data: dataBet } = useQuery(getBetAmountGql);
  // TODO to be removed
  const currency = userData!.user.balance?.currency || 'FUN';
  const balanceAmount = userData!.user.balance?.amount!;
  const { betAmount } = dataBet!;
  const {
    autoSpinsAmount,
    isStopOnAnyWin,
    isStopOnFeatureWin,
    stopOnWinExceeds,
    stopOnBalanceIncrease,
    stopOnBalanceDecrease,
    isStopOnWinExceeds,
    isStopOnBalanceIncrease,
    isStopOnBalanceDecrease,
  } = data!;

  const winExceedsSliderValue = useReactiveVar(setStopOnWinExceedsSliderValue);
  const balanceIncreaseSliderValue = useReactiveVar(setStopOnBalanceIncreaseSliderValue);
  const onAutoSpinsAmountChange = useCallback((value: number) => {
    setAutoSpinsAmount(value);
  }, []);

  const coinMultiplier = setSlotConfig().lineSet.coinAmountMultiplier;

  const handleStartAutoplay = useCallback(() => {
    if (setIsPopupOpeningInProgress() !== PopupOpeningTypes.BUY_FEATURE_POPUP) {
      AudioApi.play({ type: ISongs.SFX_UI_General });
      setLastRegularWinAmount(0);
      setAutoSpinsLeft(autoSpinsAmount);
      setIsAutoSpins(true);
      setAutoSpinsStartBalance(balanceAmount);
      setIsOpenAutoplayPopup(false);
    }
  }, [balanceAmount, autoSpinsAmount]);

  useEffect(() => {
    if (
      autoplay.conditions.stopIfSingleWinExceeds.multipliers[winExceedsSliderValue - 1]! * betAmount !==
      stopOnWinExceeds
    ) {
      setStopOnWinExceeds(betAmount);
      setStopOnWinExceedsSliderValue(1);
    }
    if (
      autoplay.conditions.stopIfBalanceIncreasesBy.multipliers[balanceIncreaseSliderValue - 1]! * betAmount !==
      stopOnBalanceIncrease
    ) {
      setStopOnBalanceIncrease(betAmount);
      setStopOnBalanceIncreaseSliderValue(1);
      setStopOnBalanceDecrease(betAmount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.wrap}>
        <div className={`${styles['autoplay-settings']} ${className}`}>
          <div className={styles['autoplay-settings__col']}>
            <div className={`${styles['autoplay-settings__item']} ${styles.btns}`}>
              <div className={styles.label}>{t('autoSpin')}</div>
              <div className="btn-group">
                {autoplay.options.map((item) => (
                  <Button
                    className="uiBtn"
                    intent="autoPlaySettingBtn"
                    key={item}
                    isActive={autoSpinsAmount === item}
                    title={`${item}`}
                    onClick={() => {
                      AudioApi.play({ type: ISongs.SFX_UI_BetChange });
                      onAutoSpinsAmountChange(item);
                    }}
                  />
                ))}
              </div>
            </div>
            {autoplay.conditions.stopIfFeatureIsWon.enabled && (
              <div className={styles['autoplay-settings__item']}>
                <div className={styles.controls}>
                  <Toggle
                    className={styles.toggle}
                    checked={isStopOnFeatureWin}
                    id="stop_on_feature_win"
                    onChange={() => {
                      setIsStopOnFeatureWin(!isStopOnFeatureWin);
                      AudioApi.play({ type: ISongs.SFX_UI_General });
                    }}
                  />
                  <div className={styles.label}>{t('autoPlayStopOnFeatureWin')}</div>
                </div>
              </div>
            )}
            {autoplay.conditions.stopOnAnyWin.enabled && (
              <div className={styles['autoplay-settings__item']}>
                <div className={styles.controls}>
                  <Toggle
                    className={styles.toggle}
                    checked={isStopOnAnyWin}
                    id="stop_on_won"
                    onChange={() => {
                      setIsStopOnAnyWin(!isStopOnAnyWin);
                      AudioApi.play({ type: ISongs.SFX_UI_General });
                    }}
                  />
                  <div className={styles.label}>{t('autoPlayStop')}</div>
                </div>
              </div>
            )}
          </div>
          <div className={styles['autoplay-settings__col']}>
            {autoplay.conditions.stopIfSingleWinExceeds.enabled && (
              <div className={styles['autoplay-settings__item']}>
                <div className={styles.controls}>
                  <Toggle
                    className={styles.toggle}
                    checked={isStopOnWinExceeds}
                    id="stop_on_win_exceeds"
                    onChange={() => {
                      setIsStopOnWinExceeds(!isStopOnWinExceeds);
                      AudioApi.play({ type: ISongs.SFX_UI_General });
                    }}
                  />
                  <div className={styles.label}>
                    {t('autoPlayStopSingleWin')}{' '}
                    <span className={styles.value}>
                      {formatNumber({
                        currency,
                        value: normalizeCoins(stopOnWinExceeds),
                        showCurrency: showCurrency(currency),
                      })}
                    </span>
                    <RangeSlider
                      disabled={!isStopOnWinExceeds}
                      className={styles.slider}
                      min={1}
                      max={autoplay.conditions.stopIfSingleWinExceeds.multipliers.length}
                      value={winExceedsSliderValue}
                      onChange={(v) => {
                        setStopOnWinExceedsSliderValue(v);
                        setStopOnWinExceeds(
                          setCoinAmount() *
                            autoplay.conditions.stopIfSingleWinExceeds.multipliers[v - 1]! *
                            coinMultiplier,
                        );
                        AudioApi.play({ type: ISongs.SFX_UI_BetChange });
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {autoplay.conditions.stopIfBalanceIncreasesBy.enabled && (
              <div className={styles['autoplay-settings__item']}>
                <div className={styles.controls}>
                  <Toggle
                    className={styles.toggle}
                    checked={isStopOnBalanceIncrease}
                    id="stop_on_balance_increase"
                    onChange={() => {
                      setIsStopOnBalanceIncrease(!isStopOnBalanceIncrease);
                      AudioApi.play({ type: ISongs.SFX_UI_General });
                    }}
                  />
                  <div className={styles.label}>
                    {t('autoPlayStopBalanceUp')}{' '}
                    <span className={styles.value}>{`${formatNumber({
                      currency,
                      value: normalizeCoins(stopOnBalanceIncrease),
                      showCurrency: showCurrency(currency),
                    })}`}</span>
                    <RangeSlider
                      disabled={!isStopOnBalanceIncrease}
                      className={styles.slider}
                      min={1}
                      max={autoplay.conditions.stopIfBalanceIncreasesBy.multipliers.length}
                      value={balanceIncreaseSliderValue}
                      onChange={(v) => {
                        setStopOnBalanceIncreaseSliderValue(v);
                        setStopOnBalanceIncrease(
                          setCoinAmount() *
                            autoplay.conditions.stopIfBalanceIncreasesBy.multipliers[v - 1]! *
                            coinMultiplier,
                        );
                        AudioApi.play({ type: ISongs.SFX_UI_BetChange });
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {autoplay.conditions.stopIfBalanceDecreasesBy.enabled && (
              <div className={styles['autoplay-settings__item']}>
                <div className={styles.controls}>
                  <Toggle
                    className={styles.toggle}
                    checked={isStopOnBalanceDecrease}
                    id="stop_on_balance_decrease"
                    onChange={() => {
                      setIsStopOnBalanceDecrease(!isStopOnBalanceDecrease);
                      AudioApi.play({ type: ISongs.SFX_UI_General });
                    }}
                  />
                  <div className={styles.label}>
                    {t('autoPlayStopBalanceDown')}{' '}
                    <span className={styles.value}>{`${formatNumber({
                      currency,
                      value: normalizeCoins(stopOnBalanceDecrease),
                      showCurrency: showCurrency(currency),
                    })}`}</span>
                    <RangeSlider
                      disabled={!isStopOnBalanceDecrease}
                      className={styles.slider}
                      min={betAmount}
                      max={balanceAmount / setCoinValue()}
                      value={stopOnBalanceDecrease}
                      onChange={(v) => {
                        setStopOnBalanceDecrease(v);
                        AudioApi.play({ type: ISongs.SFX_UI_BetChange });
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Button intent="confirm" onClick={handleStartAutoplay} disabled={autoSpinsAmount === 0} />
    </div>
  );
};

export default AutoPlaySettings;
